import {initReactI18next} from "react-i18next";
import i18n from "i18next";

i18n.use(initReactI18next).init({
    lng: "de",
    resources: {
        de: {
            translation: {
                Home: "Home",
                Language: "de",
                Association: "Gemeinde",
                AboutUs: "Über uns",
                History: "Geschichte",
                Statutes: "Satzung",
                BeMember: "Mitglied werden",
                Membership: "Mitgliedschaft",
                Islam: "Islam",
                Activities: "Aktivitäten",
                ContactUs: "Kontakt",
                Donate: "Spenden",
                Donation: "Spenden",
                PrayerTimes: "Gebetszeiten",
                Gregorian: "Gregorianisch",
                Hijri: "Hijri",
                Fajr: "Fadschr",
                Dhuhr: "Dhuhr",
                Asr: "Asr",
                Maghrib: "Maghrib",
                Isha: "Ischa",
                WelcomeMessage1: "Herzlich Willkommen bei unsere",
                WelcomeMessage2: "Islamische Gemeinde Roding",
                WelcomeMessage3: "Ein VEREIN * eine GEMEINSCHAFT * eine UMMAH",
                WelcomeMessage4:
                    "O ihr Menschen, Wir haben euch\n" +
                    "ja von einem männlichen und\n" +
                    "einem weiblichen Wesen\n" +
                    "erschaffen, und Wir haben euch zu\n" +
                    "Völkern und Stämmen gemacht,\n" +
                    "damit ihr einander kennenlernt.\n" +
                    "Gewiß , der Geehrteste von euch\n" +
                    "bei Allah ist der Gottesfürchtigste\n" +
                    "von euch. Gewiß , Allah ist\n" +
                    "Allwissend und Allkundig.",
                WelcomeMessage5:
                    "Der Prophet\n" +
                    "(Friede sei mit ihm) sagte: Bitte Allah\n" +
                    "um nützliches Wissen und suche\n" +
                    "Zuflucht bei Allah vor Wissen , das\n" +
                    "keinen Nutzen bringt",
                TownOfRoding: "Stadt Roding",
                Contact: "Kontakt",
                ContactInformation: "KONTAKT INFORMATIONEN",
                GetTouch: "Willkommen auf der IG-Roding-Kontaktseite!",
                ContactMessage:
                    "Haben Sie Fragen, Wünsche oder Anregungen? Bitte nehmen Sie Kontakt mit uns auf, wir helfen Ihnen gerne weiter!",
                FullName: "Name",
                Email: "Email",
                Message: "Nachricht",
                Subject: "Betreff",
                SendNow: "Absenden",
                AboutIslam: "Über Islam",
                TheFaith: "Die Glaube",
                TheIslam: "Der Islam",
                Education: "Bildung",
                ArabicEducation: "Arabisch",
                IslamicEducation: "Islamische Religion",
                Quran: "Koran",
                WhoWeAre: "Wer Sind Wir",
                OurAim: "Ziele",
                OurGoal: "Ziele",
                OurVision: "Vision",
                BankDetails: "BANKVERBINDUNG",
                BankName: "Bank Name",
                IBAN: "IBAN",
                BIC: "BIC",
                UseFullLinks: "NÜTZLICHE LINKS",
                LegalNotice: "Impressum",
                DataProtection: "Datenschutz",
                PillarsOfIslam: "Säulen des Islams",
                WelcomeImage2: "/images/design/igr-web-arabic_1.png",
                WelcomeImage1: "https://firebasestorage.googleapis.com/v0/b/ig-roding.appspot.com/o/IGR-EidRamadan-2025.jpg?alt=media&token=1e3c6993-f3ab-49a6-b3a4-19e6b3d2ca4a",

                // "https://firebasestorage.googleapis.com/v0/b/ig-roding.appspot.com/o/IGR-Ramadan-2025.jpg?alt=media&token=bf2d167f-fade-4061-befa-0c922a41e070",
                // "https://firebasestorage.googleapis.com/v0/b/ig-roding.appspot.com/o/eid-aladha-2024%2Figr-eid-aladha2024-de.png?alt=media&token=f61e04be-69fe-4a44-ae91-3a8dbf1cdb22",
                // WelcomeImage2: "/images/design/igr-web-german_2.png",
                WelcomeImage3: "/images/design/igr-web-german_3.png",
                WelcomeImage4: "/images/design/igr-web-german_6.png",
                Pillars: "Säulen des Islam",
                Pillar1: "Shahada",
                Pillar2: "Salat",
                Pillar3: "Zakat",
                Pillar4: "Saum",
                Pillar5: "Hadsch",
                Allah: "Allah!",
                Muhammad: "Wer is Muhammed?",
                WhatIslam: "Was ist Islam?",
                Fees: "Gebühren",
                Schedule: "Zeitplan",
                Organogram: "Organigramm",
            },
        },
        en: {
            translation: {
                Home: "Home",
                Language: "en",
                Association: "Association",
                AboutUs: "About Us",
                History: "History",
                Statutes: "Statutes",
                BeMember: "Be Member",
                Membership: "Membership",
                Islam: "Islam",
                Activities: "Activities",
                ContactUs: "Contact Us",
                Donate: "Donate",
                Donation: "Donation",
                PrayerTimes: "Prayer Times",
                Gregorian: "Gregorian",
                Hijri: "Hijri",
                Fajr: "Fajr",
                Dhuhr: "Dhuhr",
                Asr: "Asr",
                Maghrib: "Maghrib",
                Isha: "Isha",
                WelcomeMessage1: "Welcome to our",
                WelcomeMessage2: "Islamische Gemeinde Roding",
                WelcomeMessage3: "One Association * One Community, * One Ummah",
                WelcomeMessage4:
                    "O mankind, indeed We have\n" +
                    "created you from male and female\n" +
                    "and made you peoples and tribes\n" +
                    "that you may know one another.\n" +
                    "Indeed, the most noble of you in\n" +
                    "the sight of Allah is the most\n" +
                    "righteous of you. Indeed, Allah is\n" +
                    "Knowing and Acquainted.",
                WelcomeMessage5:
                    "the Messenger\n" +
                    "PBUH of Allah said:\n" +
                    '"Ask Allah for beneficial\n' +
                    "knowledge and seek refuge with\n" +
                    "Allah from knowledge that is of no\n" +
                    'benefit."',
                TownOfRoding: "Town Of Roding",
                Contact: "Contact",
                ContactInformation: "CONTACT INFORMATION",
                GetTouch: "Welcome to the IG-Roding contact page!",
                ContactMessage:
                    "Do you have any questions, requests or suggestions? Please contact us, we will be happy to help you!",
                FullName: "Full Name",
                Email: "Email",
                Message: "Message",
                Subject: "Subject",
                SendNow: "Send Now",
                AboutIslam: "About Islam",
                TheFaith: "The Faith",
                TheIslam: "The Islam",
                Education: "Education",
                ArabicEducation: "Arabic ",
                IslamicEducation: "Islamic Religion",
                Quran: "Quran",
                WhoWeAre: "Who We Are",
                OurAim: "Our Aim",
                OurGoal: "Our Goal",
                OurVision: "Our Vision",
                BankDetails: "BANK DETAILS",
                BankName: "Bank Name",
                IBAN: "IBAN",
                BIC: "BIC",
                UseFullLinks: "USEFUL LINKS",
                LegalNotice: "Legal Notice",
                DataProtection: "Data Protection",
                PillarsOfIslam: "Pillars of Islam",
                WelcomeImage2: "/images/design/igr-web-arabic_1.png",
                WelcomeImage1: "https://firebasestorage.googleapis.com/v0/b/ig-roding.appspot.com/o/IGR-EidRamadan-2025.jpg?alt=media&token=1e3c6993-f3ab-49a6-b3a4-19e6b3d2ca4a",
                //"https://firebasestorage.googleapis.com/v0/b/ig-roding.appspot.com/o/IGR-Ramadan-2025.jpg?alt=media&token=bf2d167f-fade-4061-befa-0c922a41e070",

                // "https://firebasestorage.googleapis.com/v0/b/ig-roding.appspot.com/o/eid-aladha-2024%2Figr-eid-aladha2024-ar-en.png?alt=media&token=5c15200a-f6bf-4689-b308-9e02aeedc8e6",
                //WelcomeImage2: "/images/design/igr-web-english_2.png",
                WelcomeImage3: "/images/design/igr-web-english_3.png",
                WelcomeImage4: "/images/design/igr-web-english_6.png",
                Pillars: "Pillars of Islam",
                Pillar1: "Shahada",
                Pillar2: "Salat",
                Pillar3: "Zakat",
                Pillar4: "Sawm",
                Pillar5: "Hajj",
                Allah: "Allah!",
                Muhammad: "Who is Muhammed?",
                WhatIslam: "What is Islam?",
                Fees: "Fees",
                Schedule: "Schedule",
                Organogram: "Organogram",
            },
        },
        ar: {
            translation: {
                Home: "الرئيسية",
                Language: "ar",
                Association: "الجمعية",
                AboutUs: "من نحن",
                History: "من نحن",
                Statutes: "قانون الجمعية",
                BeMember: "العضوية",
                Membership: "العضوية",
                Islam: "الإسلام",
                Activities: "الأنشطة",
                ContactUs: "تواصل معنا",
                Donate: "تبرع الآن",
                Donation: "تبرع الآن",
                PrayerTimes: "مواقيت الصلاة",
                Gregorian: "ميلادي",
                Hijri: "هجري",
                Fajr: "الفجر",
                Dhuhr: "الظهر",
                Asr: "العصر",
                Maghrib: "المغرب",
                Isha: "العشاء",
                WelcomeMessage1: "مرحبا بكم في",
                WelcomeMessage2: "الجمعية الإسلامية رودينغ",
                WelcomeMessage3: "جمعية * مجتمع واحد * أمة واحدة",
                WelcomeMessage4:
                    "يَا أَيُّهَا النَّاسُ إِنَّا خَلَقْنَاكُم مِّن ذَكَرٍ وَأُنثَىٰ\n" +
                    "وَجَعَلْنَاكُمْ شُعُوبًا وَقَبَائِلَ لِتَعَارَفُوا ۚ\n" +
                    "إِنَّ أَكْرَمَكُمْ عِندَ اللَّهِ أَتْقَاكُمْ ۚ إِنَّ اللَّهَ عَلِيمٌ خَبِيرٌ",
                WelcomeMessage5:
                    "قال رسول الله صلى الله عليه وسلم: سَلوا الله عِلمًا نافعًا وتعَوَّذوا باللهِ مِن علمٍ لا ينفعُ",
                TownOfRoding: "مدينة رودينغ",
                Contact: "تواصل معنا",
                ContactInformation: "معلومات الاتصال",
                GetTouch: "مرحباَ بكم في صفحة التواصل الخاصة بالجمعية الإسلامية رودينغ",
                ContactMessage:
                    "يسعدنا التواصل معك والإجابة على أي استفسارات لديك. سواء كنت مهتمًا بمعرفة المزيد عن مجتمعنا، أو لديك أسئلة حول أنشطتنا وخدماتنا، أو ترغب ببساطة في التواصل معنا، فنحن هنا لمساعدتك.",
                ContactMessage1:
                    "لا تتردد في التواصل معنا باستخدام معلومات الاتصال الواردة أدناه. سيبذل فريقنا المتفاني قصارى جهده للاستجابة على الفور وتزويدك بالمعلومات التي تحتاجها. نحن نقدر ملاحظاتك واقتراحاتك ومشاركتك، لأنها تساعدنا على النمو وخدمة المجتمع بشكل أفضل. نتطلع إلى الاستماع منك والترحيب بك في مجتمعنا. شكرًا لك على اهتمامك بالجمعية الإسلامية رودينغ، المانيا!",
                FullName: "الإسم الكامل",
                Email: "البريد الإلكتروني",
                Message: "اكتب رسالتك هنا",
                Subject: "الموضع",
                SendNow: "أرسل الآن",
                AboutIslam: "حول الإسلام",
                TheFaith: "الإيمان",
                TheIslam: "الإسلام",
                Education: "التعليم",
                ArabicEducation: "اللغة العربية",
                IslamicEducation: "التربية الإسلامية",
                Quran: "القرآن الكريم",
                WhoWeAre: "من نحن",
                OurAim: "أهدافنا",
                OurGoal: "أهدافنا",
                OurVision: "رؤيتنا",
                BankDetails: "الحساب المصرفي",
                BankName: "إسم البنك",
                IBAN: "IBAN",
                BIC: "BIC",
                UseFullLinks: "روابط مفيدة",
                LegalNotice: "هيئة التحرير",
                DataProtection: "حماية البيانات",
                PillarsOfIslam: "أركان الإسلام",
                WelcomeImage2: "/images/design/igr-web-arabic_1.png",
                WelcomeImage1: "https://firebasestorage.googleapis.com/v0/b/ig-roding.appspot.com/o/IGR-EidRamadan-2025.jpg?alt=media&token=1e3c6993-f3ab-49a6-b3a4-19e6b3d2ca4a",
                //"https://firebasestorage.googleapis.com/v0/b/ig-roding.appspot.com/o/IGR-Ramadan-2025.jpg?alt=media&token=bf2d167f-fade-4061-befa-0c922a41e070",

                // "https://firebasestorage.googleapis.com/v0/b/ig-roding.appspot.com/o/eid-aladha-2024%2Figr-eid-aladha2024-ar-en.png?alt=media&token=5c15200a-f6bf-4689-b308-9e02aeedc8e6",
                //WelcomeImage2: "/images/design/igr-web-arabic_2.png",
                WelcomeImage3: "/images/design/igr-web-arabic_3.png",
                WelcomeImage4: "/images/design/igr-web-arabic_6.png",
                Pillars: "أركان الإسلام",
                Pillar1: "الشهادتان",
                Pillar2: "الصلاة",
                Pillar3: "إيتاء الزكاة",
                Pillar4: "صوم رمضان",
                Pillar5: "حج البيت",
                Fees: "مصاريف",
                Allah: " الله!",
                Muhammad: "من هو محمد؟ ",
                WhatIslam: "ما هو الإسلام؟",
                Schedule: "جدول",
                Organogram: "الهيكل التنظيمي",
            },
        },
    },
    keySeparator: false,
    interpolation: {escapeValue: false},
});

export default i18n;
